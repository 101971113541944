import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {addJobsAsync} from '../GlobalState/job-slice'
import { message } from 'antd';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const processedRef = useRef(false);

    useEffect(() => {
      const handlePaymentSuccess = async () => {
        if (processedRef.current) {
            return;
          }
          processedRef.current = true;
        try {
            
          // Retrieve stored job data
         
          const jobData = localStorage.getItem(`pendingJobPost`);
         
          console.log("jobData",jobData);
          if (jobData) {
            // Parse the stored data
            const parsedJobData = JSON.parse(jobData);
            
            // Call finalPay with the stored data
            const response = await dispatch(addJobsAsync(JSON.stringify({
              data: parsedJobData,
            })));
  
            if (response.payload.data.success) {
              message.success(response.payload.data.message);
              // Clear stored data
              localStorage.removeItem(`pendingJobPost`);
              // Redirect to home
              navigate('/');
              return;
            } else {
              message.error('Something went wrong');
              navigate('/hire');
              return;
            }
          }
        } catch (error) {
          console.error('Error processing payment success:', error);
          message.error('Failed to complete job posting');
        }
      };
  
      handlePaymentSuccess();
       // Cleanup function
    // return () => {
    //     processedRef.current = false;
    //   };
    }, []);
  
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg">
          <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Processing Your Payment
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Please wait while we complete your job posting...
            </p>
            {/* Add a loading spinner here */}
          </div>
        </div>
      </div>
    );
  };
  
  export default PaymentSuccess;