import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const PaymentFailed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePaymentFailure = () => {
      try {
  
        
        // Clean up stored data
        if (localStorage.getItem(`pendingJobPost`)) {
          localStorage.removeItem(`pendingJobPost`);
        }

        message.error('Payment failed. Please try again.');
        
        // Redirect back to hire page after a short delay
        setTimeout(() => {
          navigate('/hire');
        }, 2000);
      } catch (error) {
        console.error('Error handling payment failure:', error);
        navigate('/hire');
      }
    };

    handlePaymentFailure();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <svg
              className="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Payment Failed
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Your payment was not successful. Redirecting you back to try again...
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;