import React,{useEffect, useState} from 'react';
import Footers from '../Components/Footers';
import BannerHome from '../Components/BannerHome';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import Vector from '../images/new_uiuxlogo1.png';
import { country } from '../values/constants';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Preview from '../Components/Preview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useJobStore from '../GlobalState/Jobstore';
import { message } from 'antd';
import {addJobsAsync} from '../GlobalState/job-slice'
import { useSelector, useDispatch } from 'react-redux';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import DodoPayments from 'dodopayments';

// function loadScript(src) {
//   return new Promise((resolve) => {
//     const script = document.createElement('script')
//     script.src = src
//     script.onload = () => {
//       resolve(true)
//     }
//     script.onerror = () => {
//       resolve(false)
//     }
//     document.body.appendChild(script)
//   })
// }

const PostJob = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSalary, setSelectedSalary] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedOptiontype, setSelectedOptiontype] = useState('fulltime');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOptionduration, setSelectedOptionduration] = useState('1month');
    const [value, setValue] = useState('');
    const [title, settitle] = useState('');
    const [applylink, setapplylink] = useState('');
    const [city, setCity] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [posterEmail, setposterEmail] = useState('');
    const [companyLink, setcompanyLink] = useState('');
    const [display_url, setDisplayUrl] = useState('');
    const [preview, setpreview] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [price, setprice] = useState(50);
    const [expirydate, setexpiryDate] = useState('');
    const [finalData, setFinalData] = useState({});
    const [paddle, setPaddle] = useState();
    const [isProcessing, setIsProcessing] = useState(false);

    const navigate = useNavigate();
    const priceList = [15,50,70];
    const notify = () => toast.error("Fill All Important Fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

        const notifySuccess = () => toast.success("Data Sent !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
            const dispatch = useDispatch();

            
    const category = [
        { value: 'UI/UX Design', label: 'UI/UX Design' },
        { value: 'UI Design', label: 'UI Design' },
        { value: 'UX Design', label: 'UX Design' },
        { value: 'Product Design', label: 'Product Design' },
        { value: 'UX Research', label: 'UX Research' },
        { value: 'UX Writing', label: 'UX Writing' },
      ];

      const  salary = [
        { value: 'undisclosed', label: 'Undisclosed' },
        { value: 'negotiable', label: 'Negotiable' },
        { value: '$0 - $30K', label: '$0 - $30K USD per year' },
        { value: '$30K - $60K', label: '$30K - $60K USD per year' },
        { value: '$60K - $90K', label: '$60K - $90K USD per year' },
        { value: '$90K - $120K', label: '$90K - $120K USD per year' },
        { value: '$120K - $150K', label: '$120K - $150K USD per year' },
        { value: '$150K - $180K', label: '$150K - $180K USD per year' },
        { value: '$180K - $210K', label: '$180K - $210K USD per year' },
        { value: 'over $210K', label: 'Over $210K USD per year' },
      ];

     // const navigate = useNavigate();
     const getExpiryDate  = (userSelection)=>{
      const currentTime = new Date();

      let futureTime;
      
      if (userSelection === '1week') {
       // futureTime = new Date(currentTime.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding 1 week in milliseconds
        futureTime = new Date(currentTime.getTime() +   2*60 * 1000); // Adding 1 week in milliseconds
      } else if (userSelection === '1month') {
        const nextMonth = currentTime.getMonth() + 1;
        futureTime = new Date(currentTime.getFullYear(), nextMonth, currentTime.getDate());
      } else if (userSelection === '1.5month') {
        futureTime = new Date(currentTime.getTime() + 45 * 24 * 60 * 60 * 1000); // Adding 45 days in milliseconds
      }
      
      const formattedTime = futureTime.toISOString().replace('T',' ').replace('Z',''); // Convert to UTC format suitable for PostgreSQL timestamp
      setexpiryDate(formattedTime);
    }

     useEffect(()=>{
      getExpiryDate(selectedOptionduration);
     },[selectedOptionduration]);

      const addJob = useJobStore((state) => state.addJob);


      // const getIp = async()=>{
      //   const res = await fetch('https://api64.ipify.org');
      //   console.log(res);
      //   getLocation(res);
      // }

      // const getLocation = async(ip)=>{
      //   const res = await fetch(`http://www.geoplugin.net/php.gp?ip=${ip}`);
      //   const data = await res.json();
      //   console.log(data);
      // }

      // async function displayRazorpay () {

      //   const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    
      //     if (!res){
      //       alert('Razropay failed to load!!')
      //       return 
      //     }
    
      //     const data = await fetch('http://178.16.138.73:6700/order-pay', {method: 'POST'}).then((t) => 
      //       t.json()
      //     ) 
    
      //    // console.log('datadaatatd pay',data)
    
      //   const options = {
      //     "key": "rzp_test_1zVfNQPqqM4qIr", // Enter the Key ID generated from the Dashboard
      //     "amount": "5", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      //     "currency": "USD",
      //     "name": "UI/UX JOBS HUB",
      //     "description": "Test Transaction",
      //     "image": "https://i.ibb.co/SPHr5qz/UX-Jobs-Hub.png",
      //     "order_id": data.clientSecret, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      //     "notes": {
      //         "address": "Razorpay Corporate Office"
      //     },
      //     "theme": {
      //         "color": "#3399cc"
      //     },
      //     "handler": function (response){
      //     //  console.log('response from handler', response);
      //       finalPay();
      //     }
      // };
      // const paymentObject = new window.Razorpay(options); 
      // paymentObject.open();
      // }
      const validateJobData = () => {
        if (!selectedCategory || !selectedCategory?.value) {
          message.error('Please select a job category!');
          setIsValid(false);
          return;
        }

        if (!selectedSalary || !selectedSalary?.value) {
          message.error('Please select a salary range!');
          setIsValid(false);
          return;
        }

        if (!selectedCountry || !selectedCountry?.value) {
          message.error('Please select a country!');
          setIsValid(false);
          return;
        }

        if (!title.trim()) {
          message.error('Job title is required!');
          setIsValid(false);
          return;
        }

        if (!value.trim()) {
          message.error('Job description is required!');
          setIsValid(false);
          return;
        }

        if (!applylink.trim()) {
          message.error('Application link is required!');
          setIsValid(false);
          return;
        }

        // URL validation for apply link
        if(!applylink.includes('http')){
          message.error('Please enter a valid application URL!');
          setIsValid(false);
          return;
        }

        if (!city.trim()) {
          message.error('City is required!');
          setIsValid(false);
          return;
        }

        if (!companyName.trim()) {
          message.error('Company name is required!');
          setIsValid(false);
          return;
        }

        if (!posterEmail.trim()) {
          message.error('Job poster email is required!');
          setIsValid(false);
          return;
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(posterEmail)) {
          message.error('Please enter a valid email address!');
          setIsValid(false);
          return;
        }

        if (!companyLink.trim()) {
          message.error('Company website URL is required!');
          setIsValid(false);
          return;
        }

  // URL validation for company website
        if(!companyLink.includes('http')){
          message.error('Please enter a valid company website URL!');
          setIsValid(false);
          return;
        }

        if (!display_url) {
          message.error('Company logo is required!');
          setIsValid(false);
          return;
        }
        setIsValid(true);

      }
      const handlePostJob = async (e) => {
        e.preventDefault();
        // Required field validations
        validateJobData();

        
         // notifySuccess();
        //  console.log('insode postjob success');
          //navigate('/payments');
          if(isValid){
            const AggregatedData = {
              selectedcategory: selectedCategory.value,
              selectedsalary: selectedSalary.value,
              selectedcountry: selectedCountry.value,
              selectedoptiontype: selectedOptiontype,
              selectedoptionduration: selectedOptionduration,
              value: value,
              title: title,
              applylink: applylink,
              city: city,
              companyname: companyName,
              posteremail: posterEmail,
              companywebsitelink: companyLink,
              display_url: display_url,
              price: price,
              expirydate:expirydate,
              user_email:posterEmail
            };
            setFinalData(AggregatedData);
          
          //  await displayRazorpay();
        await openCheckout();
          }
          
      }

       // Download and initialize Paddle instance from CDN
  // useEffect(() => {
  //   initializePaddle({ environment: 'sandbox', token: 'test_7fca022df3f364a824be8dc70c4',
  //     eventCallback: async (data) =>  {
  //       if (data.name === "checkout.completed"&& data.data.status==='completed') {
  //       //  console.log("checkout.completed",data);
  //         await finalPay();
  //       }
  //     } 
  //   }).then(
  //     (paddleInstance) => {
  //       if (paddleInstance) {
  //         setPaddle(paddleInstance);
  //       }
  //     },
  //   );

  // }, [finalData]);

  
  function getPriceId(price) {
    // Update these with your actual Dodo product IDs
    const priceMap = {
      15: 'pdt_UWVnh8KQ0AAwxLAzPR9Iu',
      50: 'pdt_ftT5HLo7iFCk0eEGwrs8Y',
      70: 'pdt_6eoWNd9xwzHv0AjhXaijX'
    };
    return priceMap[price] || priceMap[50]; // Default to $50 product if price not found
  }
  
  async function handlePayment() {
    try {
    setIsProcessing(true);
    
    // Store job data in localStorage before redirecting
    const jobData = {
      selectedcategory: selectedCategory?.value,
      selectedsalary: selectedSalary?.value,
      selectedcountry: selectedCountry?.value,
      selectedoptiontype: selectedOptiontype,
      selectedoptionduration: selectedOptionduration,
      value: value,
      title: title,
      applylink: applylink,
      city: city,
      companyname: companyName,
      posteremail: posterEmail,
      companywebsitelink: companyLink,
      display_url: display_url,
      price: price,
      expirydate: expirydate,
      user_email: posterEmail
    };
    
    localStorage.setItem(`pendingJobPost`, JSON.stringify(jobData));

    // Redirect to DodoPayments checkout
    const truePriceId = getPriceId(price);
    const checkoutUrl = `https://test.checkout.dodopayments.com/buy/${truePriceId}?` + 
    new URLSearchParams({
      quantity: '1',
      redirect_url: `${window.location.origin}/payment-success`,
      failure_url: `${window.location.origin}/payment-failed`,
      cancel_url: `${window.location.origin}/hire`
    }).toString();
    window.location.href = checkoutUrl;

  } catch (error) {
    console.error('Payment error:', error);
    message.error('Payment failed: ' + (error.message || 'Please try again'));
    setIsProcessing(false);
  }
  }

  // Update the openCheckout function to use the new payment handler
  const openCheckout = async() => {
    validateJobData();
    if (isValid) {
      //await handlePayment();
      await finalPay();
    }
  };
      const finalPay = async () => {
        const AggregatedData = {
          selectedcategory: selectedCategory?.value||finalData?.selectedcategory,
          selectedsalary: selectedSalary?.value||finalData?.selectedsalary,
          selectedcountry: selectedCountry?.value||finalData?.selectedcountry,
          selectedoptiontype: selectedOptiontype||finalData?.selectedoptiontype,
          selectedoptionduration: selectedOptionduration||finalData?.selectedoptionduration,
          value: value||finalData?.value,
          title: title||finalData?.title,
          applylink: applylink||finalData?.applylink,
          city: city||finalData?.city,
          companyname: companyName||finalData?.companyname,
          posteremail: posterEmail||finalData?.posteremail,
          companywebsitelink: companyLink||finalData?.companywebsitelink,
          display_url: display_url||finalData?.display_url,
          price: price||finalData?.price,
          expirydate:expirydate||finalData?.expirydate,
          user_email:posterEmail||finalData?.user_email
        };
      //  console.log('data==>', AggregatedData, finalData);
        const response = await dispatch(addJobsAsync(JSON.stringify({
          data: AggregatedData,
        })));
      //  console.log("dcedce",response);
        if(response.payload.data.success){
         // paddle?.Checkout.close();
          message.success(response.payload.data.message);
          navigate('/');
        }else{
         // paddle?.Checkout.close();
          message.error('Something went wrong');
        }
      }
      
      // const checkout = (Incomingdata) => {
      //   fetch("${process.env.REACT_APP_BACKEND_URL}/v1/api/register-job", {
      //     method:"POST",
      //     headers:{
      //       "Content-Type":"application/json"
      //     },
          
      //     body: JSON.stringify({
      //       data: Incomingdata,
      //     })
      //   })
      //   .then(res => {
      //     if (res.ok) return res.json()
      //   })
      //   .catch(e => {
      //     console.log(e.error)
      //   })
      // }

      const handlePreviewClick = () =>{
        validateJobData();
        if(isValid){
        
            setpreview(!preview)
          //  console.log('insode preview')
        }
        
      }

      const handleChange = (event) => {
        setSelectedOptiontype(event.target.value);
      };

      

      const handleFileChange = (event) => {
        const file = event.target.files[0];
      //  console.log(file);
        setSelectedFile(file);
      };

      

      const handleOptionChange = (event) => {
        setSelectedOptionduration(event.target.value);
        if(event.target.value==='1week'){
          setprice(priceList[0]);
        }else if(event.target.value==='1month'){
          setprice(priceList[1]);
        }else{
          setprice(priceList[2]);
        }
        
      };
      useEffect(() => {
        if (selectedFile!==null) {
          uploadImageData();
        }
      }, [selectedFile]);
      

      useEffect(()=>{
        
        window.scrollTo(0, 0);
      //  getIp();
      },[]);

      // useEffect(()=>{
      //   if(selectedCategory!==null&&selectedSalary!==null&&selectedCountry!==null&&value!==''&&title!==''&&applylink!==''&&city!==''&&companyName!==''&&companyLink!==''&&display_url!==''
      //   ){
      //       setIsValid(true)
      //   }else{
      //       setIsValid(false)
      //   }
      // },[selectedCategory,selectedSalary,selectedCountry,value,title,applylink,city,companyName,posterEmail,companyLink,display_url]);


      const uploadImageData = () => {
        if (!selectedFile) {
          //  console.log('No file selected.');
            return;
          }
          
        const form = new FormData();
      //  console.log('form', selectedFile.slice(22));
        form.append('image', selectedFile);
        form.set('key', 'afc4ea18db790b9f3ab9406360857bd1');
        axios
          .post('https://api.imgbb.com/1/upload', form, {
            'Content-Type': 'multipart/form-data',
          })
          .then((res) => {
          //  console.log('ddcd', res);
            setDisplayUrl(res.data.data.display_url);
          })
    
          .catch((err) => {
            console.log(err);
          });
      };

      

      
      return (
        <div>
          <div className="h-auto">
            {/* Banner */}
            {/* Banner */}
<div className="relative bg-gradient-to-b from-white to-gray-50">
  {/* Navigation */}
  <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <Link to="/" className="flex-shrink-0">
        <img 
          className="h-10 w-auto hover:opacity-90 transition-opacity" 
          src={Vector} 
          alt="Logo" 
        />
      </Link>
      
      {/* Navigation Links */}
      <div className="flex items-center space-x-8">
        <Link 
          to="/terms" 
          className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors duration-200"
        >
          Terms
        </Link>
        <Link 
          to="/privacy-policy" 
          className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors duration-200"
        >
          Privacy
        </Link>
        <Link 
          to="/contact-us" 
          className="text-gray-600 hover:text-gray-900 text-sm font-medium transition-colors duration-200"
        >
          Get in touch
        </Link>
      </div>
    </div>
  </nav>

  {/* Hero Content */}
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
    <div className="text-center">
      <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight">
        Discover Top Design Talent
      </h1>
      <p className="mt-6 text-xl sm:text-2xl text-gray-600 max-w-3xl mx-auto">
        Find Exceptional UI Designers and UX Researchers. Start Your Search Today!
      </p>
    </div>
  </div>

  {/* Decorative Elements */}
  <div className="absolute inset-x-0 bottom-0">
    <div className="h-20 bg-gradient-to-t from-gray-50 to-transparent" />
  </div>
</div>
      
            {preview && (
              <div>
                {/* Job Details Section */}
<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    {/* Section Header */}
    <div className="bg-gradient-to-r from-blue-50 via-indigo-50 to-white px-8 py-6 border-b border-gray-100">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">💼</span>
        <h2 className="text-xl font-semibold text-gray-900">Job Details</h2>
      </div>
      <p className="mt-2 text-sm text-gray-600">Fill in the information about your job posting</p>
    </div>

    {/* Form Content */}
    <div className="p-8">
      <div className="grid gap-8">
        {/* Title Input */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">✍️</span>
            <label htmlFor="job-title" className="block text-sm font-medium text-gray-700">
              Job Title<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <input
            id="job-title"
            type="text"
            placeholder="e.g. Senior UI Designer"
            className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            required
          />
        </div>

        {/* Category Select */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">🎯</span>
            <label htmlFor="job-category" className="block text-sm font-medium text-gray-700">
              Category<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <Select
            id="job-category"
            defaultValue={selectedCategory}
            onChange={setSelectedCategory}
            options={category}
            className="w-full"
            classNamePrefix="select"
            required
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: '#F9FAFB',
                borderColor: '#E5E7EB',
                borderRadius: '0.75rem',
                padding: '4px',
                '&:hover': {
                  borderColor: '#6B7280',
                },
              }),
            }}
          />
        </div>

        {/* Job Type Radio Group */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-4">
            <span className="text-lg">⏰</span>
            <label className="block text-sm font-medium text-gray-700">
              Employment Type
            </label>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {[
              { id: 'fulltime', label: 'Full-time', icon: '👨‍💼' },
              { id: 'contract', label: 'Contract', icon: '📄' },
              { id: 'internship', label: 'Internship', icon: '🎓' },
            ].map((type) => (
              <label
                key={type.id}
                className={`relative flex items-center p-4 rounded-xl cursor-pointer ${
                  selectedOptiontype === type.id 
                    ? 'bg-blue-50 border-2 border-blue-500' 
                    : 'bg-gray-50 border border-gray-200 hover:bg-gray-100'
                }`}
              >
                <input
                  type="radio"
                  id={type.id}
                  name="jobType"
                  value={type.id}
                  checked={selectedOptiontype === type.id}
                  onChange={handleChange}
                  className="sr-only"
                />
                <div className="flex items-center space-x-3">
                  <span className="text-xl">{type.icon}</span>
                  <span className="text-sm font-medium text-gray-900">{type.label}</span>
                </div>
              </label>
            ))}
          </div>
        </div>

        {/* Salary Select */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">💰</span>
            <label htmlFor="job-salary" className="block text-sm font-medium text-gray-700">
              Salary Range (optional)
            </label>
          </div>
          <Select
            id="job-salary"
            defaultValue={selectedSalary}
            onChange={setSelectedSalary}
            options={salary}
            className="w-full"
            classNamePrefix="select"
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: '#F9FAFB',
                borderColor: '#E5E7EB',
                borderRadius: '0.75rem',
                padding: '4px',
                '&:hover': {
                  borderColor: '#6B7280',
                },
              }),
            }}
          />
        </div>

        {/* How To Apply Input */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">🔗</span>
            <label htmlFor="job-apply-link" className="block text-sm font-medium text-gray-700">
              How To Apply<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <input
            id="job-apply-link"
            type="text"
            value={applylink}
            onChange={(e) => setapplylink(e.target.value)}
            placeholder="Enter application URL or email address"
            className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            required
          />
        </div>
      </div>
    </div>
  </div>
</div>
      
             {/* Job Location Section */}
<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    {/* Section Header */}
    <div className="bg-gradient-to-r from-green-50 via-emerald-50 to-white px-8 py-6 border-b border-gray-100">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">🌎</span>
        <h2 className="text-xl font-semibold text-gray-900">Job Location</h2>
      </div>
      <p className="mt-2 text-sm text-gray-600">Where will this position be based?</p>
    </div>

    {/* Form Content */}
    <div className="p-8">
      <div className="grid gap-8">
        {/* Country Select */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">🏳️</span>
            <label htmlFor="job-country" className="block text-sm font-medium text-gray-700">
              Country<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <Select
            id="job-country"
            defaultValue={selectedCountry}
            onChange={setSelectedCountry}
            options={country}
            className="w-full"
            classNamePrefix="select"
            required
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: '#F9FAFB',
                borderColor: '#E5E7EB',
                borderRadius: '0.75rem',
                padding: '4px',
                '&:hover': {
                  borderColor: '#6B7280',
                },
              }),
            }}
          />
        </div>

        {/* City Input */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">🏙️</span>
            <label htmlFor="job-city" className="block text-sm font-medium text-gray-700">
              City<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <input
            id="job-city"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="e.g. San Francisco"
            className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors"
            required
          />
        </div>

        
      </div>
    </div>
  </div>
</div>
      
                {/* Company Details Section */}
<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    {/* Section Header */}
    <div className="bg-gradient-to-r from-purple-50 via-indigo-50 to-white px-8 py-6 border-b border-gray-100">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">🏢</span>
        <h2 className="text-xl font-semibold text-gray-900">Company Details</h2>
      </div>
      <p className="mt-2 text-sm text-gray-600">Tell us about your organization</p>
    </div>

    {/* Form Content */}
    <div className="p-8">
      <div className="grid gap-8">
        {/* Company Name Input */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">✨</span>
            <label htmlFor="company-name" className="block text-sm font-medium text-gray-700">
              Company Name<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <input
            id="company-name"
            type="text"
            value={companyName}
            onChange={(e) => setcompanyName(e.target.value)}
            placeholder="e.g. Acme Corporation"
            className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors"
            required
          />
        </div>

        {/* Email Input */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">📧</span>
            <label htmlFor="poster-email" className="block text-sm font-medium text-gray-700">
              Job Poster Email<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <input
            id="poster-email"
            type="email"
            value={posterEmail}
            onChange={(e) => setposterEmail(e.target.value)}
            placeholder="e.g. hiring@company.com"
            className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors"
            required
          />
        </div>

        {/* Company Logo Upload */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">🎨</span>
            <label htmlFor="company-logo" className="block text-sm font-medium text-gray-700">
              Company Logo<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-xl hover:border-purple-500 transition-colors">
            <div className="space-y-1 text-center">
            {display_url ? (
        // Show image preview if uploaded
        <div className="flex flex-col items-center">
          <img 
            src={display_url} 
            alt="Company logo preview" 
            className="h-32 w-32 object-contain rounded-lg shadow-sm"
          />
          <button
            onClick={() => setDisplayUrl(null)}
            className="mt-2 text-sm text-red-500 hover:text-red-700"
          >
            Remove image
          </button>
        </div>
      ) : (
        // Show upload interface if no image
        <>
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="company-logo"
              className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500"
            >
              <span>Upload a file</span>
              <input
                id="company-logo"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="sr-only"
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </>
      )}
            </div>
          </div>
        </div>

        {/* Company URL Input */}
        <div className="relative">
          <div className="flex items-center space-x-3 mb-2">
            <span className="text-lg">🔗</span>
            <label htmlFor="company-url" className="block text-sm font-medium text-gray-700">
              Company Website<span className="text-red-500 ml-1">*</span>
            </label>
          </div>
          <div className="mt-1 relative rounded-xl shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">https://</span>
            </div>
            <input
              id="company-url"
              type="text"
              value={companyLink}
              onChange={(e) => setcompanyLink(e.target.value)}
              placeholder="www.yourcompany.com"
              className="w-full pl-20 px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
                {/* Job Description Section */}
<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    {/* Section Header */}
    <div className="bg-gradient-to-r from-amber-50 via-yellow-50 to-white px-8 py-6 border-b border-gray-100">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">📝</span>
        <h2 className="text-xl font-semibold text-gray-900">
          Job Description<span className="text-red-500 ml-1">*</span>
        </h2>
      </div>
      <p className="mt-2 text-sm text-gray-600">Describe the role and requirements in detail</p>
    </div>

    {/* Rich Text Editor */}
    <div className="p-8">
      <div className="relative">
        <div className="flex items-center space-x-3 mb-4">
          <span className="text-lg">✍️</span>
          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-700">Write your job description</span>
            <span className="text-xs text-gray-500 mt-1">
              Include key responsibilities, requirements, benefits, and company culture
            </span>
          </div>
        </div>

        {/* Editor Container */}
        <div className="border border-gray-200 rounded-xl overflow-hidden">
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            className="h-96 bg-gray-50"
            required
            modules={{
              toolbar: [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link'],
                ['clean']
              ],
            }}
            formats={[
              'header',
              'bold', 'italic', 'underline', 'strike',
              'list', 'bullet',
              'link'
            ]}
            placeholder="Start typing your job description here..."
            style={{
              height: '384px', // h-96 equivalent
            }}
          />
        </div>

        {/* Helper Text */}
        <div className="mt-4 flex items-center space-x-2 text-sm text-gray-500">
          <span className="text-lg">💡</span>
          <span>
            Pro tip: Be specific about requirements and make your description scannable with bullet points
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
       
      {/* Highlight Job Section */}
<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    {/* Section Header */}
    <div className="bg-gradient-to-r from-rose-50 via-pink-50 to-white px-8 py-6 border-b border-gray-100">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">⭐</span>
        <h2 className="text-xl font-semibold text-gray-900">Highlight Job</h2>
      </div>
      <p className="mt-2 text-sm text-gray-600">Choose how long you want your job posting to be active</p>
    </div>

    {/* Duration Options */}
    <div className="p-8">
      <div className="space-y-6">
        <div className="flex items-center space-x-3 mb-4">
          <span className="text-lg">📅</span>
          <label className="block text-sm font-medium text-gray-700">
            Post Job Duration
          </label>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {[
            { id: '1week', label: '1 Week', icon: '🔥', subtext: 'Best for urgent hiring' },
            { id: '1month', label: '1 Month', icon: '📊', subtext: 'Most popular choice' },
            { id: '1.5month', label: '45 Days', icon: '🌟', subtext: 'Maximum visibility' },
          ].map((duration) => (
            <label
              key={duration.id}
              className={`relative flex flex-col p-4 rounded-xl cursor-pointer transition-all ${
                selectedOptionduration === duration.id 
                  ? 'bg-rose-50 border-2 border-rose-500 shadow-sm' 
                  : 'bg-gray-50 border border-gray-200 hover:bg-gray-100'
              }`}
            >
              <input
                type="radio"
                id={`pin-${duration.id}`}
                name="jobDuration"
                value={duration.id}
                checked={selectedOptionduration === duration.id}
                onChange={handleOptionChange}
                className="sr-only"
              />
              <div className="flex flex-col items-center text-center space-y-2">
                <span className="text-2xl">{duration.icon}</span>
                <span className="font-medium text-gray-900">{duration.label}</span>
                <span className="text-xs text-gray-500">{duration.subtext}</span>
              </div>

              {/* Checkmark for selected option */}
              {selectedOptionduration === duration.id && (
                <div className="absolute top-2 right-2">
                  <span className="text-rose-500 text-lg">✓</span>
                </div>
              )}
            </label>
          ))}
        </div>

        {/* Helper Text */}
        <div className="mt-6 flex items-center space-x-2 text-sm text-gray-500">
          <span className="text-lg">💡</span>
          <span>
            Longer durations increase your chances of finding the perfect candidate
          </span>
        </div>
      </div>
    </div>
  </div>
</div>



                {/* Net Price Display */}
<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    {/* Section Header */}
    <div className="bg-gradient-to-r from-emerald-50 via-teal-50 to-white px-8 py-6 border-b border-gray-100">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">💰</span>
        <h2 className="text-xl font-semibold text-gray-900">Net Price</h2>
      </div>
      <p className="mt-2 text-sm text-gray-600">Total cost for your job posting</p>
    </div>

    {/* Price Display */}
    <div className="p-8">
      <div className="flex items-center justify-between p-6 bg-gray-50 rounded-xl border border-gray-200">
        <div className="flex items-center space-x-4">
          <span className="text-lg">💎</span>
          <div>
            <p className="text-sm font-medium text-gray-500">Total Amount</p>
            {/* <p className="text-3xl font-bold text-gray-900">${price}</p> */}
            <p className="text-3xl font-bold text-gray-900">${0}</p>
          </div>
        </div>
        
        {/* Optional: Add a tooltip or info icon */}
        <div className="flex items-center space-x-2 text-gray-500">
          <span className="text-lg">ℹ️</span>
          <span className="text-sm">Final price including all taxes</span>
        </div>
      </div>

      {/* Optional: Price Breakdown */}

      {/* Optional: Payment Note */}
      <div className="mt-6 flex items-center space-x-2 text-sm text-gray-500">
        <span className="text-lg">🔒</span>
        <span>Secure payment processing with encrypted transactions</span>
      </div>
    </div>
  </div>
</div>
              </div>
            )}
      
            {/* Preview Component */}
            {!preview && (
              <Preview
                selectedCategory={selectedCategory.value}
                jobId="nill"
                selectedSalary={selectedSalary.value}
                selectedCountry={selectedCountry.value}
                selectedOptiontype={selectedOptiontype}
                selectedFile={selectedFile}
                selectedOptionDuration={selectedOptionduration}
                value={value}
                title={title}
                applylink={applylink}
                city={city}
                companyName={companyName}
                posterEmail={posterEmail}
                companyTwitterLink={companyLink}
                display_url={display_url}
              />
            )}
          </div>
      
          {/* Action Buttons */}
          <div className="flex flex-row justify-end h-auto w-full lg:w-7/12 mx-auto my-14">
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 mx-2 px-4 rounded-lg"
              onClick={() => handlePreviewClick()}
            >
              {preview ? 'Preview Job' : 'Go Back'}
            </button>
            <ToastContainer />
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-lg"
              onClick={(e) => handlePostJob(e)}
            >
              Post Your Job
            </button>
            <ToastContainer />
          </div>
      
          <Footers />
        </div>
      );
}

export default PostJob;