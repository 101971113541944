import React, {useEffect} from 'react'
import Vector from '../images/new_uiuxlogo1.png';
import Footers from '../Components/Footers';
import { Link } from 'react-router-dom';

const Terms = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <React.Fragment>
      <div className="min-h-screen bg-gray-50">
        {/* Header Navigation */}
        <nav className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="flex items-center">
                <img className="h-8 w-auto" src={Vector} alt="Logo" />
              </Link>
              <div className="flex space-x-8">
                <Link to="/terms" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Terms</Link>
                <Link to="/privacy-policy" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Privacy</Link>
                <Link to="/contact-us" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Get in touch</Link>
              </div>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Title */}
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-12">
            Terms & Conditions
          </h1>

          {/* Content Sections */}
          <div className="space-y-12">
            {/* Overview Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Overview</h2>
              <p className="text-gray-600 leading-relaxed">
                By accessing the website at https://uiuxjobshub.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
              </p>
            </section>

            {/* Use License Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Use License</h2>
              <ul className="space-y-4 text-gray-600">
                <li className="flex space-x-3">
                  <span className="text-blue-500">•</span>
                  <div>
                    <p className="mb-4">Permission is granted to temporarily download one copy of the materials (information or software) on uiuxjobshub's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                    <ul className="list-decimal pl-8 space-y-2 text-gray-500">
                      <li>modify or copy the materials;</li>
                      <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                      <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                    </ul>
                  </div>
                </li>
                <li className="flex space-x-3">
                  <span className="text-blue-500">•</span>
                  <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by uiuxjobshub at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
                </li>
              </ul>
            </section>

            {/* Disclaimer Section */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Disclaimer</h2>
              <ul className="space-y-4 text-gray-600">
                <li className="flex space-x-3">
                  <span className="text-blue-500">•</span>
                  <p>The materials on uiuxjobshub's website are provided on an 'as is' basis. uiuxjobshub makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
                </li>
                <li className="flex space-x-3">
                  <span className="text-blue-500">•</span>
                  <p>Further, uiuxjobshub does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>
                </li>
              </ul>
            </section>

            {/* Additional Sections */}
            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Limitations</h2>
              <p className="text-gray-600 leading-relaxed">
                In no event shall uiuxjobshub or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on uiuxjobshub's website, even if uiuxjobshub or a uiuxjobshub authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
              </p>
            </section>

            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Accuracy of Materials</h2>
              <p className="text-gray-600 leading-relaxed">
                The materials appearing on uiuxjobshub's website could include technical, typographical, or photographic errors. uiuxjobshub does not warrant that any of the materials on its website are accurate, complete or current. uiuxjobshub may make changes to the materials contained on its website at any time without notice. However uiuxjobshub does not make any commitment to update the materials.
              </p>
            </section>

            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Links</h2>
              <p className="text-gray-600 leading-relaxed">
                uiuxjobshub has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by uiuxjobshub of the site. Use of any such linked website is at the user's own risk.
              </p>
            </section>

            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Modifications</h2>
              <p className="text-gray-600 leading-relaxed">
                uiuxjobshub may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
              </p>
            </section>

            <section className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
              <p className="text-gray-600 leading-relaxed">
                For any questions or concerns regarding about the Terms of Service, you may contact us using the following details: hello@uiuxjobshub.com
              </p>
            </section>
          </div>
        </main>

        <Footers />
      </div>
    </React.Fragment>
  )
}

export default Terms;