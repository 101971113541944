import React, {useEffect} from 'react'
import Vector from '../images/new_uiuxlogo1.png';
import Footers from '../Components/Footers';
import { Link } from 'react-router-dom';

const Refund = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <React.Fragment>
      <div className="min-h-screen bg-gray-50">
        {/* Header Navigation */}
        <nav className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="flex items-center">
                <img className="h-8 w-auto" src={Vector} alt="Logo" />
              </Link>
              <div className="flex space-x-8">
                <Link to="/terms" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Terms</Link>
                <Link to="/privacy-policy" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Privacy</Link>
                <Link to="/contact-us" className="text-gray-600 hover:text-gray-900 text-sm font-medium">Get in touch</Link>
              </div>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">
              Refund Policy
            </h1>

            {/* Content Sections */}
            <div className="space-y-8 text-gray-700">
              <div className="border-b pb-6">
                <p className="text-sm text-gray-500">Last updated: <span className="font-medium">24/12/2024</span></p>
                <p className="mt-4">Thank you for choosing <span className="font-semibold">UIUXJobsHUB</span>. We strive to ensure complete satisfaction with our services. This refund policy outlines our guidelines for refunds and cancellations.</p>
              </div>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-900">Eligibility for Refunds</h2>
                <p>We offer refunds under the following circumstances:</p>
                <ul className="list-disc pl-6 space-y-2 ml-4">
                  <li>If the service is not delivered as promised due to an error on our end</li>
                  <li>If technical issues prevent access to paid features and cannot be resolved promptly</li>
                  <li>If you cancel your subscription within the specified refund period</li>
                </ul>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-900">Refund Period</h2>
                <p>Refund requests must be submitted within <span className="font-medium">7 days</span> of the payment date. Requests made after this period will not be eligible for a refund.</p>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-900">Non-Refundable Cases</h2>
                <ul className="list-disc pl-6 space-y-2 ml-4">
                  <li>Changes of mind after purchasing a subscription or service</li>
                  <li>Failure to use the service during the subscription period</li>
                  <li>Issues caused by third-party software or tools not affiliated with our platform</li>
                </ul>
              </section>

              <section className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-900">Refund Process</h2>
                <ol className="list-decimal pl-6 space-y-2 ml-4">
                  <li>Contact our support team at <a href="mailto:adminmaker@uiuxjobshub.com" className="text-blue-600 hover:text-blue-800 hover:underline">adminmaker@uiuxjobshub.com</a></li>
                  <li>Provide your payment receipt, order ID, and a detailed explanation</li>
                  <li>Our team will review your request within 3-5 business days</li>
                  <li>Approved refunds will be processed to your original payment method within 7-10 business days</li>
                </ol>
              </section>

              <section className="space-y-4 bg-gray-50 p-6 rounded-lg mt-8">
                <h2 className="text-2xl font-semibold text-gray-900">Need Help?</h2>
                <p>If you have any questions about our refund policy or need assistance, please don't hesitate to contact us:</p>
                <p className="font-medium">
                  Email: <a href="mailto:adminmaker@uiuxjobshub.com" className="text-blue-600 hover:text-blue-800 hover:underline">adminmaker@uiuxjobshub.com</a>
                </p>
              </section>
            </div>
          </div>
        </main>

        <Footers />
      </div>
    </React.Fragment>
  )
}

export default Refund;